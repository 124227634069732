.date-input-container {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .date-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    color: transparent; /* Hide text color */
    caret-color: transparent; /* Hide caret */
  }
  
  .date-input::-webkit-datetime-edit,
  .date-input::-webkit-inner-spin-button,
  .date-input::-webkit-clear-button {
    display: none; /* Hide the text and default icons */
  }
  
  .date-input-container label {
    position: absolute;
    top: 8px;
    left: 10px;
    pointer-events: none;
  }
  
  .calendar-icon {
    color: #888;
    font-size: 20px;
  }
  