
.table-header{
    background: linear-gradient(to right, #373195, #5fa1d6);
 } 
 
 .table-wrapper {
   border-radius:20px ;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width:100%;
    overflow-y: auto;
    max-height: 400px;
 }
 
 
 
 .custom-number-input {
     width: 70px; /* Set desired width */
   text-align: right;
     border-radius: 5px; /* Set desired border radius */
     margin-right: 20px;
     margin-bottom: 10px;
     min-height: 20px !important;
 
   }
 
   .custom-total-input{
     width: 120px; /* Set desired width */
     text-align: right;
       border-radius: 8px; /* Set desired border radius */
      
 
   }
 
   .confirm-button {
     /* border: 2px solid black; */
     width: 100px;
     background:linear-gradient(to right, #373195, #5fa1d6); 
     border-radius: 5px;
     font-size: 14px;
     color: white;
     height: 23px;
     border: none;
     cursor: pointer;
     margin-left: 15px;
 
 
   }
 
   .slider-confirm{
     display: flex;
     align-items: center;
     gap: 10px;
   
   }
 
   tbody {
     max-height: 200px; /* Set the max height for the table body */
     overflow-y: auto;  /* Allow vertical scrolling */
   }
   
   
 
   .bill-footer{
     display: flex;
   justify-content: space-between;
   margin-top: 20px;
   /* padding: 20px; */
   gap: 10px;
   }
 
   /* .number-input-container, .slider-confirm {
     flex: 1;
   } */
   .head-label {
     color: #453f8f;/* Adds space between rows */
     font-weight:500
   
   }
   
  
   @media (max-width: 768px) {
     .bill-footer{
       flex-direction: column;
     }
   
     .number-input-container, .slider-confirm {
       width: 100%; /* Full width on mobile */
     }
   }