.print-button {
    margin-bottom: 20px;
  }
  
  .report-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .report-table th,
  .report-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    #reporttable,
    #reporttable * {
      visibility: visible;
    }
    #reporttable {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .print-button {
      display: none;
    }
  }