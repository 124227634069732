.print-button {
  margin-bottom: 20px;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
}

.report-table th,
.report-table td {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 15px;
}

/* print.css */
@media print {
  body {
    font-family: Arial, sans-serif;
    color: #000;
  }

  #printableContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: none;
  }

  .printdiv {
    display: none; /* Hide the print and close buttons in print */
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th, td {
    padding: 10px;
    border: 1px solid #000;
    text-align: left;
  }

  th {
    background-color: #f0f0f0;
  }
}


@media screen {
  .printdiv {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
}


.grid-table {
  width: 100%;
  border-collapse: collapse;
}

.printdiv {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  border-top: 1px solid #ddd;
}
.printbtn{
  padding: 8px 12px;
  background: linear-gradient(to right, #5fa1d6, #373195);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.grid-table thead th,
.grid-table tbody td {
  border: 1px solid #ddd;
  padding: 5px;
}