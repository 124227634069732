.loginBgImage {
  background-image: url('../../asset/background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.buttSingIn {
  background: linear-gradient(to right, #5fa1d6, #373195);
}


.triangle {
  /* width: 10px;
	height: 10px; */
  border-width: 50px 0 0 60px;
  border-color: transparent transparent transparent #372f93;
  border-style: solid;
}

@font-face {
  font-family: 'MalayalamFont';
  src: url('../../asset/font-family/FMLGP0BTT.ttf') format('truetype'),
    url('../../asset/font-family/FMLGP0NTT.ttf') format('truetype');
}

@font-face {
  font-family: 'SignFontHeading';
  src: url('../../asset/font-family/Happy\ Lucky\ Free.ttf') format('truetype'),
    url('../../asset/font-family/Happy\ Lucky\ Free.ttf') format('truetype');
}

.malayalam {
  font-family: 'MalayalamFont', sans-serif;
}

.signformate {
  font-family: 'SignFontHeading', sans-serif;
  font-size: 56pt;
  color: #3d3f89;
  line-height: 70px;
}


.signBgImage {
  background-image: url('../../asset/loginimg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.signinputBorder {
  border: 1px solid #372f93;
  padding-left: 20px;
}

.signinputBorder:focus{
  outline-color:#372f93;
  border-radius: 0;
}

.signinputBorder::placeholder {
  font-size: 12px; /* Set font size for placeholder text */
  color: #888; 
}

.signShadow {
  box-shadow: 0px 0px 4px 0px #a3a3a3;
}
.loginBgImage {
  background-image: url('../../asset/background1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.buttSingIn {
  background: linear-gradient(to right, #5fa1d6, #373195);
}


.triangle {
  /* width: 10px;
	height: 10px; */
  border-width: 50px 0 0 60px;
  border-color: transparent transparent transparent #372f93;
  border-style: solid;
}

@font-face {
  font-family: 'MalayalamFont';
  src: url('../../asset/font-family/FMLGP0BTT.ttf') format('truetype'),
    url('../../asset/font-family/FMLGP0NTT.ttf') format('truetype');
}

@font-face {
  font-family: 'SignFontHeading';
  src: url('../../asset/font-family/Happy\ Lucky\ Free.ttf') format('truetype'),
    url('../../asset/font-family/Happy\ Lucky\ Free.ttf') format('truetype');
}

.malayalam {
  font-family: 'MalayalamFont', sans-serif;
}

.signformate {
  font-family: 'SignFontHeading', sans-serif;
  font-size: 56pt;
  color: #3d3f89;
  line-height: 70px;
}


.signBgImage {
  background-image: url('../../asset/loginimg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.signinputBorder {
  border: 1px solid #372f93;
  padding-left: 20px;
}

.signinputBorder:focus{
  outline-color:#372f93;
  border-radius: 0;
}

.signinputBorder::placeholder {
  font-size: 12px; /* Set font size for placeholder text */
  color: #888; 
}

.signShadow {
  box-shadow: 0px 0px 4px 0px #a3a3a3;
}
