.number-input-value {
    text-align: right;
  }
  .number-input-value {
    text-align: right;
  }

  /* styles.css */
.modalBillCustom {
  position: absolute;
  top: 14%; /* Adjust position from the top */
  left: -4%; /* Adjust position from the left */
 
}

@media (max-width: 768px) {
  .modalBillCustom {
  
    height: 90vh; /* Optional: set height for smaller screens */
    left: 2%;
    right: 2%;
  }
}
